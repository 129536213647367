import React, {Component} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Col,} from 'reactstrap';
import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import appStore from "../../store/AppStore";
import {FormStore, Input, Validation, ValidationTooltip} from "../validation";

const formStore = new FormStore();

class ConfirmDialogStore {
    @observable
    show = false;

    @observable
    title = '';

    @observable
    summary = '';

    @observable
    content = '';

    @observable
    onOk;

    @observable
    onCancel;

    @observable
    okLabel = '확인';

    @observable
    okColor = 'success';

    @action.bound
    clear() {
        this.title = '';
        this.summary = '';
        this.content = '';
        this.onOk = undefined;
        this.onCancel = undefined;
        this.okLabel = '확인';
        this.okColor = 'success';
    }

    @action.bound
    open(title, summary, onOk, content = '', okLabel = '확인', okColor = 'success', onCancel) {
        this.clear();
        this.title = title;
        this.summary = summary;
        this.content = content;
        this.onOk = onOk;
        this.onCancel = onCancel;
        this.okLabel = okLabel;
        this.okColor = okColor;
        this.show = true;
    }

    @action.bound
    openSave(title, summary, onOk, content = '') {
        this.open(title, summary, onOk, content, '저장');
    }

    @action.bound
    openAdd(title, summary, onOk, content = '') {
        this.open(title, summary, onOk, content, '추가');
    }

    @action.bound
    openRemove(title, summary, onOk, content = '', okLabel = '삭제') {
        this.open(title, summary, onOk, content, okLabel, 'danger');
    }

    @action.bound
    close() {
        this.show = false;
    }
}

const confirmDialogStore = new ConfirmDialogStore();
window.confirmDialogStore = confirmDialogStore;

@observer
class ConfirmDialog extends Component {

    render() {
        return (
            <Modal
                isOpen={confirmDialogStore.show}>
                <ModalHeader>
                        {confirmDialogStore.title}
                </ModalHeader>
                <ModalBody className="text-center">
                    {
                        confirmDialogStore.summary &&
                        <div style={{fontSize: '14px', minWidth: '300px', padding: '30px'}} key="confirm-summary">
                            {confirmDialogStore.summary}
                        </div>
                    }
                    {
                        confirmDialogStore.content &&
                        <div className="text-center" key="confirm-content">
                            {confirmDialogStore.content}
                        </div>
                    }

                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => {
                        confirmDialogStore.onCancel && confirmDialogStore.onCancel();
                        confirmDialogStore.close()
                    }}>취소</Button>
                    <Button onClick={() => {
                        confirmDialogStore.onOk && confirmDialogStore.onOk();
                        confirmDialogStore.close();
                    }} bsstyle={confirmDialogStore.okColor}>
                        {confirmDialogStore.okLabel}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export {ConfirmDialog, confirmDialogStore};