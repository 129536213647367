import React from 'react';
import {Redirect, Route, Router, Switch, withRouter} from 'react-router-dom';
import {observable} from 'mobx';
import {observer} from 'mobx-react';
import {IntroView} from './IntroView';
import appStore from '../store/AppStore';
import {HISTORY, navigate} from '../helper/HistoryHelper';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './Common.css';

import Styles from './App.module.scss';
import {Button, Col, Container, Label, Row} from 'reactstrap';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import {FormControl, MenuItem, OutlinedInput, Select} from '@material-ui/core';
import Navigation from '../component/navigation/Navigation';
import SideBar from '../component/sidebar/SideBar';
import {ConfirmDialog} from '../component/dialog/ConfirmDialog';
import {Notification} from '../component/dialog/Notification';
import {LoginDialog,loginDialogStore} from '../component/dialog/LoginDialog';
import ShareButtonModal from "../component/input/ShareButtonModal";
import {AlertDialog} from "../component/dialog/AlertDialog";


const theme = createMuiTheme({
    palette   : {
        type: 'dark', // Switching the dark mode on is a single property value change.
    },
    typography: {
        useNextVariants: true,
    },
});


class DivWith extends React.Component {

    componentWillUpdate(nextProp) {
        appStore.currentUrl = window.location.href;
        appStore.currentPath = nextProp.location.pathname;
    }

    render() {
        return (<div>{this.props.children}</div>);
    }
}

const DivWithRouter = withRouter(DivWith);

const NotFound = () => {
    return (
        <div>
            <h1>페이지가 없습니다.</h1>
        </div>
    );
};


@observer
class App extends React.Component {

    @observable
    familySite = '';

    render() {
        return (
            <MuiThemeProvider theme={theme}>

                {/* 헤더 */}
                <Navigation/>

                {/* 메인 페이지 */}
                <div>
                    <Router history={HISTORY}>
                        <DivWithRouter>
                            <Switch>
                                {/*<Route path="/intro/:hash" component={IntroView}/>*/}
                                <Route path="/intro" component={IntroView}/>
                                <Redirect exact from="/" to="/intro/"/>
                                <Route component={NotFound}/>
                            </Switch>
                        </DivWithRouter>
                    </Router>
                </div>

                {/* 사이드 바 */}
                <SideBar/>

                {/* 하단 푸터 */}
                <ConfirmDialog/>
                <AlertDialog/>
                <LoginDialog/>
                <Notification/>
                <ShareButtonModal/>
            </MuiThemeProvider>

        );
    }
}

export default App;
