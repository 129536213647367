import React from 'react';
import ReactDOM from 'react-dom';
import appStore from './store/AppStore';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import {AppContainer} from 'react-hot-loader';
import App from './view/App';
import {HISTORY} from './helper/HistoryHelper';
import { ParallaxProvider } from 'react-scroll-parallax';

// 주소표시줄에 직접입력시 처리
appStore.currentPath = HISTORY.location.pathname;

// 쿠키를 확인하여 현재 세션상태를 결정한다.
// appStore.fetchSessionInfo();


const rootEl = document.getElementById('root');

ReactDOM.render(
    <ParallaxProvider>
        <App/>
    </ParallaxProvider>,
    rootEl
);

/*if (module.hot) {
    module.hot.accept('./view/App', () => {
        ReactDOM.render(
            <AppContainer>
                <ParallaxProvider>
                    <App/>
                </ParallaxProvider>
            </AppContainer>,
            rootEl
        );
    });
}*/








