import createHistory from 'history/createHashHistory';


const HISTORY = createHistory(); // TODO : 무조건 가장 먼저 실행되어야 함. IoC 라이브러리 도입 검토 .;

const navigate = url => {
    HISTORY.push(url);
};

const navigateReplace = url => {
    HISTORY.replace(url);
};


export {HISTORY, navigate, navigateReplace};