import {notificationRef} from '../component/dialog/Notification';
import * as moment from 'moment';
class CommonHelper {
    static range(a, b) {
        let result = [];
        for (let i = a; i < b; i++) {
            result.push(i);
        }
        return result;
    }

    static findKeys(m, ...keys) {
        let result = {};
        m.forEach((v, k) => keys.includes(k) && (result[k] = v));
        return result;
    }

    static omit(obj, ...keys) {
        let result = Object.assign({}, obj);
        keys.forEach(key => delete result[key]);
        return result;
    }

    static clearValue(m) {
        m.keys().forEach(k => m.set(k, ''));
    }

    static mapToObject(m) {
        let obj = {};
        m.forEach((v, k) => {
            obj[k] = v;
        });
        return obj;
    };


    static copyPropertiesToMap(obj, m) {
        Object.entries(obj).forEach(
            ([k, v]) => m.set(k, v)
        );
    }

    static objecToMap(obj){
        let m = new Map();
        CommonHelper.copyPropertiesToMap(obj, m);
        return m;
    }

    static urlHostname(data) {
        let a = document.createElement('a');
        a.href = data;
        return a.hostname;
    }

    static notifyInfo(message) {
        notificationRef.addNotification({
            level: 'info',
            position: 'tc',
            autoDismiss: 2,
            message: message
        });
    }

    static notifyWarn(message) {
        notificationRef.addNotification({
            level: 'warning',
            position: 'tc',
            autoDismiss: 2,
            message: message
        });
    }

    static yearRange(from, to){
        let diff = to - from;
        let diffs = CommonHelper.range(0, diff + 1);
        return diffs.map( d => moment(from, 'YYYY').add(d , 'year').format('YYYY'))
    }
}

export default CommonHelper;