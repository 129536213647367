import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

const AUTH = 'authKey';
const ID = 'id';
const USER_ID = 'userId';
const USER_NAME = 'userName';
const IS_ADMIN = 'admin';
const SIDEBAR_OPEN = 'sideBarOpen';

export const SIDEBAR_WIDTH = {opened: 200, collapsed: 50};

export const CookieKeys = {AUTH, ID, USER_ID, USER_NAME, IS_ADMIN, SIDEBAR_OPEN};

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const UPLOADED_BASE_URL = process.env.REACT_APP_UPLOADED_BASE_URL;

export const URL = {API_BASE_URL, UPLOADED_BASE_URL};

export const DARK_THEME = createMuiTheme({
    palette   : {
        type   : 'dark', // Switching the dark mode on is a single property value change.
        primary: {
            main: '#fff',
        }
    },
    typography: {
        useNextVariants: true,
    }
});

export const LIGHT_THEME = createMuiTheme({
    palette   : {
        type   : 'light', // Switching the dark mode on is a single property value change.
        primary: {
            main: '#000',
        }
    },
    typography: {
        useNextVariants: true,
    }
});