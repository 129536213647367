import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import Styles from './AlertDialog.module.scss';

class AlertDialogStore {
    @observable
    show = false;

    @observable
    title = '';

    @observable
    summary = '';

    @observable
    content = '';

    @observable
    onExit = undefined;

    @action.bound
    clear() {
        this.title = '';
        this.summary = '';
        this.content = '';

        this.okLabel = '확인';
        this.okColor = 'success';
        this.onExit = undefined;
    }

    @action.bound
    open(title, summary, content = '', onExit) {
        this.clear();
        this.title = title;
        this.summary = summary;
        this.content = content;
        this.show = true;
        this.onExit = onExit;
    }


    @action.bound
    close() {
        this.show = false;
    }
}

const alertDialogStore = new AlertDialogStore();

@observer
class AlertDialog extends Component {

    close(){
        alertDialogStore.close();
    }

    render() {
        return (
            <Modal
                centered
                show={alertDialogStore.show}
                onExit={!!alertDialogStore.onExit ? () => alertDialogStore.onExit() : undefined}
                onHide={this.close}
                className={Styles.ModalBox}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <b>{alertDialogStore.title}</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{alertDialogStore.content}</p>
                    <div className="text-right">
                        <Button onClick={() => this.close()} variant="secondary">
                            확인
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export {AlertDialog, alertDialogStore};