import React from 'react';
import PropTypes from 'prop-types';
import FormStore from './FormStore';
import {Tooltip} from 'reactstrap';
import {observer} from 'mobx-react';


@observer
class ValidationTooltip extends React.Component {

    render() {
        let {store, id, name, style} = this.props;
        return (
            <Tooltip id="name'" placement="top" className="in" style={style} target={id} isOpen={store.hasTried.has(name) && store.getErrorMessages(name).length > 0}>
                {
                    store.getErrorMessages(name)[0]
                }
            </Tooltip>
        );
    }
}

ValidationTooltip.propTypes = {
    store: PropTypes.instanceOf(FormStore).isRequired,
    name: PropTypes.string.isRequired
};

export default ValidationTooltip;