import React, {Component} from 'react';
import {Button, Input, InputGroup, InputGroupAddon, Modal, ModalBody, ModalHeader} from 'reactstrap';
import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import {Pinterest} from 'react-social-sharing'
import {FaLink} from 'react-icons/fa'
import copy from 'copy-to-clipboard';
import CommonHelper from '../../helper/CommonHelper';

class ShareButtonStore {
    @observable
    isOpen = false;

    @action.bound
    open() {
        this.isOpen = true;
    }

    @action.bound
    close() {
        this.isOpen = false;
    }
}

const shareButtonStore = new ShareButtonStore();

@observer
class ShareButtonModal extends Component {
    render() {
        return (
            <Modal
                centered
                isOpen={shareButtonStore.isOpen} backdrop toggle={() => shareButtonStore.isOpen = !shareButtonStore.isOpen}>
                <ModalHeader toggle={() => shareButtonStore.isOpen = !shareButtonStore.isOpen}>
                    공유하기
                </ModalHeader>
                <ModalBody className="text-center">
                    <Pinterest link={window.location.href}/>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <Button onClick={() => {
                                copy(window.location.href);
                                CommonHelper.notifyInfo('클립보드에 URL 이 복사되었습니다.')
                            }}>
                                <FaLink/>
                            </Button>
                        </InputGroupAddon>
                        <Input value={window.location.href} disabled/>
                    </InputGroup>

                </ModalBody>
            </Modal>
        );
    }
}

ShareButtonModal.propTypes = {};

export default ShareButtonModal;
export {shareButtonStore};
