import * as qs from 'qs';
import axios from 'axios';
import {URL} from '../constant/AppConst';
import {navigateReplace} from './HistoryHelper';
import appStore from '../store/AppStore';

const axiosDefault = axios.create({
    method: 'post',
    baseURL: URL.API_BASE_URL,
    timeout: 20000,
    withCredentials: true,
    responseType: 'json'
});


export default class FetchHelper {

    static GET = 'get';
    static POST = 'post';
    static PUT = 'put';
    static DELETE = 'delete';
    static APPLICATION_JSON = 'application/json';
    static APPLICATION_X_WWW_FORM_URL_ENCODED = 'application/x-www-form-urlencoded';
    static MULTIPART_FORM_DATA = 'multipart/form-data';
    static TEXT_PLAIN = 'text/plain';

    /**
     * api 호출을 위한 기본 http 헤더를 얻는다.
     * @param contentType
     * @return {*}
     */
    static getDefaultHeader(contentType) {
        return {
            'Content-Type': contentType
        };
    }

    /**
     * api 호출
     * @param contentType
     * @param method
     * @param url
     * @param bodyObject post시에 필요한 변수
     * @param success 성공시 콜백
     * @param beforeSend 요청 전 실행 콜백
     * @param complete 완료시 콜백
     * @param error 오류시 콜백
     * @param responseType 반환타입
     * @return {Promise.<void>}
     */
    static async fetch(contentType, method, url, bodyObject, {success, beforeSend, complete, error, timeout}, responseType) {
        // 전송 전 콜백 실행
        beforeSend && beforeSend();

        let axiosConfig = {
            url: url,
            method: method,
            headers: FetchHelper.getDefaultHeader(contentType)
        };

        if (method === FetchHelper.GET) {
            axiosConfig.method = FetchHelper.GET;
            axiosConfig.params = bodyObject;
        } else {
            if (contentType === FetchHelper.APPLICATION_X_WWW_FORM_URL_ENCODED)
                axiosConfig.data = qs.stringify(bodyObject, {allowDots : true});
            else
                axiosConfig.data = bodyObject;
        }

        if (responseType)
            axiosConfig.responseType = responseType;

        if (timeout)
            axiosConfig.timeout = timeout;

        let result;
        try {
            result = await axiosDefault(axiosConfig);
            success && success(result.data);

        } catch (e) {
            console.error(e);
            if (e.response) {
                if (e.response.status === 403) {
                    console.log('로그인 만료됨. 로그인으로 이동합니다');
                    FetchHelper.errorHandler();
                } else {
                    error && error(e.response.data);
                }
            } else {
                error && error(e);
            }
        } finally {
            complete && complete();
        }

    }

    static errorHandler() {
        // AppStore.failedPath 따위의 변수에 현재 url을 저장.
        appStore.failedPath = appStore.currentPath;
        // AppStore.isLogin 을 false 로 변경.
        appStore.isLogin = false;
        // Location#navgate 를 써서 / 로 이동
        navigateReplace('/');
    }


    /**
     * application/json Content-Type 으로 post 한다.
     * @param url
     * @param bodyObject
     * @param success
     * @param beforeSend
     * @param complete
     * @param error
     * @return {Promise.<void>}
     */
    static async fetchJsonPost(url, bodyObject, {success, beforeSend, complete, error}) {
        return FetchHelper.fetch(FetchHelper.APPLICATION_JSON, FetchHelper.POST, url, bodyObject, {
            success,
            beforeSend,
            complete,
            error
        });
    }

    /**
     * application/x-www-url-encoded 로 post 한다.
     * @param url
     * @param bodyObject
     * @param success
     * @param beforeSend
     * @param complete
     * @param error
     * @return {Promise.<void>}
     */
    static async fetchUrlEncodedPost(url, bodyObject, {success, beforeSend, complete, error, timeout}) {
        return FetchHelper.fetch(FetchHelper.APPLICATION_X_WWW_FORM_URL_ENCODED, FetchHelper.POST, url, bodyObject, {
            success,
            beforeSend,
            complete,
            error,
            timeout
        });
    }

    /**
     * application/x-www-url-encoded 로 post 한다.
     * @param url
     * @param bodyObject
     * @param success
     * @param beforeSend
     * @param complete
     * @param error
     * @return {Promise.<void>}
     */
    static async fetchUrlEncodedGet(url, bodyObject, {success, beforeSend, complete, error, timeout}) {
        return FetchHelper.fetch(FetchHelper.APPLICATION_X_WWW_FORM_URL_ENCODED, FetchHelper.GET, url, bodyObject, {
            success,
            beforeSend,
            complete,
            error,
            timeout
        });
    }

    static async fetchPlainTextPost(url, bodyObject, {success, beforeSend, complete, error}) {
        return FetchHelper.fetch(FetchHelper.TEXT_PLAIN, FetchHelper.POST, url, bodyObject, {
            success,
            beforeSend,
            complete,
            error
        });
    }

    static async upload(file, {success, beforeSend, complete, error, timeout, uploadProgress, cancelToken}) {
        // 전송 전 콜백 실행
        beforeSend && beforeSend();

        let axiosConfig = {
            url: '/upload/draft',
            method: FetchHelper.POST,
            headers: FetchHelper.getDefaultHeader(FetchHelper.MULTIPART_FORM_DATA),
            cancelToken : cancelToken
        };

        let formData = new FormData();
        formData.append('file', file);
        axiosConfig.data = formData;

        if (uploadProgress)
            axiosConfig.onUploadProgress = uploadProgress;

        if (timeout)
            axiosConfig.timeout = timeout;


        let result;
        try {
            result = await axiosDefault(axiosConfig);
            success && success(result.data);

        } catch (e) {
            console.error(e);
            if (e.response) {
                if (e.response.status === 403) {
                    console.log('로그인 만료됨. 로그인으로 이동합니다');
                    FetchHelper.errorHandler();
                }
                error && error(e.response.data);
            } else {
                error && error(e);
            }
        } finally {
            complete && complete();
        }

    }
}
