import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Container, Nav, Navbar, NavbarBrand, NavItem, NavLink, Row} from 'reactstrap';
import Styles from './Navigation.module.scss';
import {navigate} from '../../helper/HistoryHelper';
import {observer} from 'mobx-react';
import {observable} from 'mobx';
import appStore from '../../store/AppStore';
import {Animated} from 'react-animated-css';
import {CSSTransition} from "react-transition-group";
import './Navigation.css';
import {FaBars} from "react-icons/fa";


const CmfSubmenu = observer(({title, to}) => {
    let isActive = false;
    if (to === '/')
        isActive = appStore.currentPath === '/';
    else
        isActive = appStore.currentPath.startsWith(to);

    return (
        <div
            className={`${Styles.NavLink} ${isActive ? Styles.Active : ''}`}
            style={{padding: '0 50px', display: 'block'}} onClick={() => navigate(to)}>
            {title}
        </div>
    );
});

@observer
class CmfNavLink extends Component {

    @observable
    hover = false;

    render() {
        let {title, to, padding, description, children} = this.props;


        let isActive = false;
        if (to === '/')
            isActive = appStore.currentPath === '/';
        else
            isActive = appStore.currentPath.startsWith(to);

        if (!padding)
            padding = '15px 40px';
        else
            padding = `0 ${padding}px`;

        return (

            <div onMouseEnter={() => this.hover = true} onMouseLeave={() => this.hover = false}>
                <div
                    className={`${Styles.NavLink} ${isActive ? Styles.Active : ''} ${appStore.pageTheme === 'light' ? Styles.LightTheme : ''}`}
                    style={{padding}} onClick={() => navigate(to)}>
                    {title}
                </div>
                {
                    children ?
                        <CSSTransition in={this.hover} timeout={200} classNames="fade" unmountOnExit>
                            {/*<Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={this.hover} animationInDuration={200} animationOutDuration={200}>*/}
                            <div className={Styles.SubMenuWrap}>
                                <Container>
                                    <Row>
                                        <Col xs={6} className={Styles.SubMenus}>
                                            {
                                                children
                                            }
                                        </Col>
                                        <Col xs={2}>
                                            <img src="/asset/images/image-copy.jpg"
                                                 style={{position: 'absolute', right: '0'}}/>
                                        </Col>
                                        <Col xs={4}>
                                            <div className={`${Styles.SubMenuTitle} mb-3`}>
                                                {title}
                                            </div>
                                            <div className={`${Styles.SubMenuText} mb-3`}>
                                                {description}
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </CSSTransition>
                        : null
                }
            </div>
        );
    }

}


/**
 * 네비게이션 (GNB)
 */
@observer
class Navigation extends Component {

    render() {
        return (
            <div style={{position: 'absolute', width: '100%', backgroundColor: 'white', zIndex: '999'}}>
                <div className={Styles.TopHeaderBar}>
                    <div style={{height: '20px'}}/>
                    <div className={Styles.HambargWrap}>
                        <Button className={Styles.Hambarg} onClick={e => appStore.sideBarOpen = true}>
                            <FaBars/>
                        </Button>
                    </div>
                </div>
                <Container>
                    <Navbar className={Styles.GNB}>
                        <NavbarBrand className={Styles.Brand}><img src="/asset/img/logo.png" alt=""/></NavbarBrand>
                        <Nav className={Styles.Menus}>
                            <NavItem className={Styles.Menu}>
                                <NavLink href="#/intro/home" className={Styles.NavLink}>HOME</NavLink>
                            </NavItem>
                            <NavItem className={Styles.Menu}>
                                <NavLink href="#/intro/portfolio" className={Styles.NavLink}>PORTFOLIO</NavLink>
                            </NavItem>
                            <NavItem className={Styles.Menu}>
                                <NavLink href="#/intro/tech" className={Styles.NavLink}>TECH STACK</NavLink>
                            </NavItem>
                            <NavItem className={Styles.Menu}>
                                <NavLink href="#/intro/member" className={Styles.NavLink}>MEMBER</NavLink>
                            </NavItem>
                            <NavItem className={Styles.Menu}>
                                <NavLink href="#/intro/contact" className={Styles.NavLink}>CONTACT US</NavLink>
                            </NavItem>
                        </Nav>
                    </Navbar>
                </Container>
            </div>
        );
    }

}

Navigation.propTypes = {
    isDark: PropTypes.bool,
};

Navigation.defauptProps = {
    isDark: false,
};

export default Navigation;