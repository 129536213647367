import {action, observable} from 'mobx';
import Cookies from 'js-cookie';
import FetchHelper from '../helper/FetchHelper';
import {CookieKeys} from '../constant/AppConst';
import moment from 'moment';
import {toJS, computed} from 'mobx';
import {navigateReplace} from '../helper/HistoryHelper';

window.toJS = toJS;

/**
 * 앱 전반에 관련된 상태
 */
class AppStore {

    failedPath = '/';

    @observable
    now = moment();

    @observable
    pageTheme = 'dark';

    @observable
    isLogin = !!Cookies.get(CookieKeys.ID);

    @observable
    userId = '';

    @observable
    userName = Cookies.get(CookieKeys.USER_NAME);

    @observable
    company = {};

    @observable
    permissions = [{id:'/login'}];

    @observable
    isAdmin = false;

    @observable
    currentUrl = '';

    @observable
    currentPath = '/';

    @observable
    sideBarOpen = false;

    @observable
    codeMap = new Map();

    @computed
    get codeGroups(){
        return this.codeMap.values();
    }

    @action.bound
    toggleSideBar() {
        this.sideBarOpen = !this.sideBarOpen;
        Cookies.set(CookieKeys.SIDEBAR_OPEN, this.sideBarOpen);
    }

    @observable
    isUpdatingSession = false;

    @action.bound
    updateSession(id, username, type, company) {
        this.userId = id;
        this.userName = username;
        this.company = company;
        if (type == 'SUPER')
            this.isAdmin = true;
        else
            this.isAdmin = false;
    }




    @action.bound
    clearSession() {
        this.userId = '';
        this.userName = '';
        this.company = {};
        this.permissions = [{id:'/login'}];
        Cookies.remove(CookieKeys.SIDEBAR_OPEN);
    }

    array_move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    };
    /**
     * 로그인을 요청한다.
     * @param form 로그인폼
     * @param success 성공콜백
     * @param fail 실패콜백
     */
    @action.bound
    fetchLogin(form, success, fail, beforeSend, complete) {
        console.log(form);
        let store = this;
        FetchHelper.fetchJsonPost('/user/login', form, {
            beforeSend: () => {
                store.isUpdatingSession = true;
                beforeSend();
            },
            success: response => {
                success(response);
                let {id, name, type, company} = response;
                store.updateSession(id, name, type, company);
                store.isLogin = true;

            },
            error: response => fail(response),
            complete: () => {
                store.isUpdatingSession = false;
                complete();
            }
        });
    }

    /**
     * 로그아웃
     */
    @action.bound
    fetchLogout() {
        let store = this;
        FetchHelper.fetchJsonPost('/user/logout', {}, {
            success: () => {
                store.isAdmin = false;
                store.isLogin = false;
                store.clearSession();
            }
        });

    }

    /**
     * 상담원 식별자가 쿠키에 남아있을때 세션정보를 재 로드한다.
     */
    fetchSessionInfo() {
        let store = this;
        FetchHelper.fetchJsonPost(
            '/user/session-touch',
            {},
            {
                beforeSend: () => store.isUpdatingSession = true,
                success: response => {
                    if (response) {
                        let {id, name, type, company} = response;
                        store.updateSession(id, name, type, company);
                        store.isLogin = true;
                    } else {
                        store.clearSession();
                        store.isAdmin = false;
                        store.isLogin = false;
                    }
                },
                error: () => {
                    // 로그인 실패시
                    store.clearSession();
                    store.isLogin = false;
                },
                complete: () => store.isUpdatingSession = false
            }
        );
    }
}

const appStore = new AppStore();
window.appStore = appStore;
export default appStore;