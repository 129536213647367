import React, {Component} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, Label, Row,} from 'reactstrap';
import {action, observable} from 'mobx';
import {observer} from 'mobx-react';
import appStore from '../../store/AppStore';
import Styles from './LoginDialog.module.scss';
import {FormStore, Input, Validation, ValidationTooltip} from '../validation';
import {MdClear, MdRemove, MdAdd} from 'react-icons/md';

const formStore = new FormStore();

class LoginDialogStore {
    @observable
    show = false;

    @observable
    title = '';

    @observable
    summary = '';

    @observable
    content = '';

    @observable
    onOk;

    @observable
    onCancel;

    @observable
    okLabel = '확인';

    @observable
    okColor = 'success';

    @action.bound
    clear() {
        this.title = '';
        this.summary = '';
        this.content = '';
        this.onOk = undefined;
        this.onCancel = undefined;
        this.okLabel = '확인';
        this.okColor = 'success';
    }

    @action.bound
    open() {
        formStore.clear();
        this.show = true;
    }

    @action.bound
    close() {
        formStore.clear();
        this.show = false;
    }
}

const loginDialogStore = new LoginDialogStore();
window.loginDialogStore = loginDialogStore;

@observer
class LoginDialog extends Component {

    render() {
        return (
            <Modal
                centered={true}
                className={Styles.LoginDialog}
                isOpen={loginDialogStore.show}>
                <ModalBody className="text-center">
                    <div className={Styles.CloseDiv}>
                        <img
                            src="/asset/images/kidp-logo.png"
                            alt=""/>
                        <a className={Styles.CloseButton} onClick={()=>loginDialogStore.close()}>
                            <MdClear />
                        </a>
                    </div>
                    <div className={Styles.Content} key="confirm-summary">
                        <div className={Styles.TitleDiv}>
                            <Label className={Styles.Title}>L O G I N</Label>
                        </div>
                        <div className="row" style={{paddingTop:25}}>
                            <div className="form-group col-md-12 col-sm-12 col-xs-12" style={{padding:0}}>
                                <div className="text-left">
                                    <Input id="loginid"
                                           name="loginid"
                                           store={formStore}
                                           className={`form-control ${Styles.LoginInput}`}
                                           validations={[
                                               new Validation(/^.+$/, '아이디를 입력해주세요.')
                                           ]}
                                           placeholder="아이디"
                                           validOnBlur
                                    />
                                    <ValidationTooltip store={formStore} name="loginid" id="loginid" style={{marginTop: -35}}/>
                                </div>
                            </div>
                            <div className="form-group col-md-12 col-sm-12 col-xs-12" style={{padding:0}}>
                                <div className="text-left">

                                    <Input store={formStore}
                                           id="password"
                                           type="password"
                                           name="password"
                                           placeholder="비밀번호"
                                           className={`form-control ${Styles.LoginInput}`}
                                           validations={[new Validation(/^.+$/, '비밀번호를 입력해주세요.')]}
                                           onKeyPress={e => {
                                               if (!formStore.doCheckValid())
                                                   return;
                                               if (e.key === 'Enter') {
                                                   appStore.fetchLogin(
                                                       formStore.valueMap,
                                                       response => this.failMessage = '',
                                                       response => this.failMessage = response.message,
                                                       () => this.isFetching = true,
                                                       () => this.isFetching = false,
                                                   );
                                               }
                                           }}
                                           validOnBlur
                                    />
                                    <ValidationTooltip store={formStore} name="password" id="password" style={{marginTop: -35}}/>
                                </div>
                            </div>
                            <div className="form-group col-md-12 col-sm-12 col-xs-12" style={{padding:0}}>
                                <Button className={Styles.LoginButton} onClick={e => {
                                    appStore.fetchLogin(
                                        formStore.valueMap,
                                        response => loginDialogStore.close(),
                                        response => this.failMessage = response.message,
                                        () => this.isFetching = true,
                                        () => this.isFetching = false,
                                    );

                                }}>
                                    LOGIN
                                </Button>
                            </div>
                            <div className={`form-group col-md-12 col-sm-12 col-xs-12 ${Styles.LinkDiv}`} style={{padding:0}}>
                                <a href={"http://www.designdb.com/?menuno=702&act=join_agree"} target="_blank" >회원가입</a>
                                <a href={"http://www.designdb.com/?menuno=702&act=find_id"} target="_blank">아이디 찾기</a>
                                <a href={"http://www.designdb.com/?menuno=702&act=find_pw"} target="_blank">비밀번호 찾기</a>
                            </div>
                            <div className={`form-group col-md-12 col-sm-12 col-xs-12 ${Styles.LogoDiv}`} style={{paddingTop: 90}}>
                                <div>
                                    <img
                                        src="/asset/images/kidp-logo.png"
                                        alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>

                </ModalBody>
            </Modal>
        );
    }
}

export {LoginDialog, loginDialogStore};