import React, {Component} from 'react';
import Styles from './IntroView.module.scss'
import {
    Button,
    Col,
    Container,
    Row,
} from 'reactstrap';
import FormStore from "../component/validation/FormStore";
import Validation from "../component/validation/Validation";
import {observer} from "mobx-react";
import {observable} from "mobx";
import {Animated} from "react-animated-css";
import appStore from "../store/AppStore";
import {Card, CardColumns, CardDeck, CardGroup, Form} from "react-bootstrap";
import GoogleMapReact from 'google-map-react';
import {alertDialogStore} from "../component/dialog/AlertDialog";
import {SectionsContainer, Section} from 'react-fullpage';
import Carousel from "react-bootstrap/Carousel";
import FetchHelper from "../helper/FetchHelper";
import CommonHelper from "../helper/CommonHelper";


const escKorea = {lat: 37.548434, lng: 126.674354};

class IntroStore {
    @observable
    portfolioRef;

    @observable
    techRef;

    @observable
    memberRef;

    @observable
    contactRef;

    @observable
    fullPageRef;

    @observable
    firstSectionShow = true;
    @observable
    secondSectionShow = false;
    @observable
    thirdSectionShow = false;
    @observable
    fourthSectionShow = false;
    @observable
    fifthSectionShow = false;
    @observable
    carouselIndex = 0;
    @observable
    animating;
    @observable
    sixthSectionShow = false;
    @observable
    carouselAnimateShow = [];

    @observable
    show = false;
}

const introStore = new IntroStore();
window.introStore = introStore;


const applyFormStore = new FormStore();

@observer
class IntroView extends Component {

    componentWillMount() {
        appStore.pageTheme = 'dark';
    }

    componentDidMount() {
        window.location.hash = "/intro/home"
    }


    send = () => {
        FetchHelper.fetchJsonPost(`/contact/send`,
            applyFormStore.values,
            {
                success: response => {
                    CommonHelper.notifyInfo("전송되었습니다.")
                }
            }
        )
    };


    render() {
        let options = {
            activeClass: 'active', // the class that is appended to the sections links
            anchors: ['/intro/home', '/intro/portfolio', '/intro/tech', '/intro/member', '/intro/contact'], // the anchors for each sections
            arrowNavigation: false, // use arrow keys
            className: 'SectionContainer', // the class name for the section container
            delay: 1000, // the scroll animation speed
            navigation: false, // use dots navigatio
            scrollBar: false, // use the browser default scrollbar
            sectionClassName: 'Section', // the section class name
            sectionPaddingTop: '0', // the section top padding
            sectionPaddingBottom: '0', // the section bottom padding
            verticalAlign: false // align the content of each section vertical
        };
        return (

            <div>
                <SectionsContainer {...options}>
                    <Section>
                        <header>
                            <Container className='w-100 h-100 d-flex justify-content-end align-items-center'>
                                <div className={Styles.carouselBox}>
                                    <Carousel>
                                        <Carousel.Item>
                                            <div>
                                                <img
                                                    className='d-block mb-5 rounded w-100'
                                                    src="/asset/img/withhyuImg.jpg"
                                                    alt="First slide"
                                                />
                                            </div>
                                            <div className={Styles.projectTag}>
                                                <h1 className='font-weight-bolder text-white pb-3'>함께한대</h1>
                                                <span>#GNUBOARD</span><span>#PHP</span>
                                                <p>따뜻한'나눔' 아름다운'동행' <br/> 사단법인 함께한대 단체 소개 및 활동스토리, 일시/정기 후원참여</p>
                                            </div>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <div>
                                                <img
                                                    className='d-block mb-5 rounded w-100'
                                                    src="/asset/img/pickImg600.jpg"
                                                    alt="Second slide"
                                                />
                                            </div>
                                            <div className={Styles.projectTag}>
                                                <h1 className='font-weight-bolder text-white pb-3'>픽앤픽</h1>
                                                <span>#React</span><span>#Java</span><span>#MobX</span>
                                                <p>Community Sharing <br/> 사지않고 빌려쓰는, 빌려주고 돈을버는 커뮤니티 쉐어링 서비스 'PiCK&PiCK'
                                                </p>
                                            </div>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <div>
                                                <img
                                                    className='d-block mb-5 rounded w-100'
                                                    src="/asset/img/cultureImg600.jpg"
                                                    alt="Third slide"
                                                />
                                            </div>
                                            <div className={Styles.projectTag}>
                                                <h1 className='font-weight-bolder text-white pb-3'>문화N티켓</h1>
                                                <span>#React</span><span>#Java</span><span>#MobX</span>
                                                <p>모든 문화정보 확인에서 예매까지 <br/> 공연전시단체를 지원하고 차별없는 국민의 문화생활을 위한 '문화관람지원플랫폼'
                                                    문화N티켓</p>
                                            </div>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <div>
                                                <img
                                                    className='d-block mb-5 rounded w-100'
                                                    src="/asset/img/cmfImg.jpg"
                                                    alt="Forth slide"
                                                />
                                            </div>
                                            <div className={Styles.projectTag}>
                                                <h1 className='font-weight-bolder text-white pb-3'>CMF</h1>
                                                <span>#React</span><span>#Java</span><span>#MobX</span>
                                                <p>Color, Material, Finishing <br/> CMF컨설팅, 트랜드세미나 등의 기업지원 소재중심디자인지원센터
                                                    'DK WORKS'</p>
                                            </div>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <div>
                                                <img
                                                    className='d-block mb-5 rounded w-100'
                                                    src="/asset/img/tadangImg.jpg"
                                                    alt="Forth slide"
                                                />
                                            </div>
                                            <div className={Styles.projectTag}>
                                                <h1 className='font-weight-bolder text-white pb-3'>서울연구원</h1>
                                                <span>#React</span><span>#Java</span><span>#MobX</span>
                                                <p>서울연구원 <br/> 타당성 검토/조사/검증 사업조회, 민자지원사업조회 및 위치정보</p>
                                            </div>
                                        </Carousel.Item>
                                    </Carousel>
                                </div>
                            </Container>
                        </header>
                    </Section>
                    <Section>
                        <Container className='d-flex justify-content-center align-items-center h-100 flex-column'>
                            <div className={Styles.title}>PORTFOLIO <div className={Styles.redPoint}/></div>
                            <CardDeck>
                                <Card>
                                    <Card.Img variant="top" src="/asset/img/withhyuImg.jpg"/>
                                    <Card.Body>
                                        <Card.Title>함께한대</Card.Title>
                                        <Card.Link href="http://www.xn--hp0bw2f652blba.org/"
                                                   target='_blank'>바로가기</Card.Link>
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Img variant="top" src="/asset/img/pickImg600.jpg"/>
                                    <Card.Body>
                                        <Card.Title>픽앤픽</Card.Title>
                                        <Card.Link href="https://picknpick.world/#/intro/"
                                                   target='_blank'>바로가기</Card.Link>
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Img variant="top" src="/asset/img/cultureImg600.jpg"/>
                                    <Card.Body>
                                        <Card.Title>문화N티켓</Card.Title>
                                        <Card.Link href="https://www.culture.go.kr/ticket/main"
                                                   target='_blank'>바로가기</Card.Link>
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Img variant="top" src="/asset/img/cmfImg.jpg"/>
                                    <Card.Body>
                                        <Card.Title>CMF</Card.Title>
                                        <Card.Link href="http://cmf.designdb.com/#/intro"
                                                   target='_blank'>바로가기</Card.Link>
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Img variant="top" src="/asset/img/tadangImg.jpg"/>
                                    <Card.Body>
                                        <Card.Title>서울연구원</Card.Title>
                                        <Card.Link href="https://tadang.esc-korea.com/#/login"
                                                   target='_blank'>바로가기</Card.Link>
                                    </Card.Body>
                                </Card>
                            </CardDeck>
                        </Container>
                    </Section>
                    <Section>
                        <Container className='d-flex justify-content-center align-items-center h-100 flex-column'
                                   id="tech" ref={ref => introStore.techRef = ref}>
                            <div className={Styles.title}>TECH STACK <div className={Styles.redPoint}/></div>
                            <Row>
                                <Col md={2} xs={4} className='p-0 d-flex justify-content-center align-items-center'>
                                    <Button style={{background: '#fff', border: 0, outline: 'none', boxShadow: 'none'}}
                                            onClick={() => alertDialogStore.open(
                                                'Java 기반의 백엔드',
                                                '',
                                                '20년이 넘도록 널리쓰여 그 성능과 유용함을 검증받고 현재도 발전을 거듭하고 있는 Java 및 JVM 기반 언어들을 백엔드로 사용합니다. ' +
                                                '강력한 오픈소스 생태계를 바탕으로 저렴하면서도 고성능의 어플리케이션을 구축 가능합니다.'
                                            )}>
                                        <img className='w-100'
                                             src="/asset/img/java.png" alt=""/>
                                    </Button>
                                </Col>
                                <Col md={2} xs={4} className='p-0 d-flex justify-content-center align-items-center'>
                                    <Button style={{background: '#fff', border: 0, outline: 'none', boxShadow: 'none'}}
                                            onClick={() => alertDialogStore.open(
                                                'es6 및 React 를 적용한 프론트엔드',
                                                '',
                                                '최근 여러 기술기업에서 프론트엔드 기술 스택으로 가장 많이 쓰이는 React 를 프론트엔드로 사용합니다.' +
                                                '개발에 필요한 여러 컴포넌트 및 모델을 미리 구성하므로써 더욱 정교한 코드를 작성하는것이 가능합니다.' +
                                                '또한 최소한의 변경으로 React Native 를 통한 하이브리드 앱 개발을 가능하게 합니다.'
                                            )}>
                                        <img className='w-100'
                                             src="/asset/img/react.png" alt=""/>
                                    </Button>
                                </Col>
                                <Col md={2} xs={4} className='p-0 d-flex justify-content-center align-items-center'>
                                    <Button style={{background: '#fff', border: 0, outline: 'none', boxShadow: 'none'}}
                                            onClick={() => alertDialogStore.open(
                                                'es6 및 React 를 적용한 프론트엔드',
                                                '',
                                                '최근 여러 기술기업에서 프론트엔드 기술 스택으로 가장 많이 쓰이는 React 를 프론트엔드로 사용합니다.' +
                                                '개발에 필요한 여러 컴포넌트 및 모델을 미리 구성하므로써 더욱 정교한 코드를 작성하는것이 가능합니다.' +
                                                '또한 최소한의 변경으로 React Native 를 통한 하이브리드 앱 개발을 가능하게 합니다.'
                                            )}>
                                        <img className='w-100'
                                             src="/asset/img/reactnative.png" alt=""/>
                                    </Button>
                                </Col>
                                <Col md={2} xs={4} className='p-0 d-flex justify-content-center align-items-center'>
                                    <Button style={{background: '#fff', border: 0, outline: 'none', boxShadow: 'none'}}
                                            onClick={() => alertDialogStore.open(
                                                'MobX 를 이용한 상태관리',
                                                '',
                                                '프론트엔드 개발에서의 상태관리는 특히 SPA 에서 중요합니다.' +
                                                '@observable 데코레이터를 통해 직관적인 상태관리 코드 작성을 가능하게 해 주는 MobX 를 적용하므로써 React 코드의 품질을 향상시키고 각 컴포넌트가 상태에 따라 반응하기 쉽게 작성할 수 있도록 합니다.'
                                            )}>
                                        <img className='w-100'
                                             src="/asset/img/mobx.png" alt=""/>
                                    </Button>
                                </Col>
                                <Col md={2} xs={4} className='p-0 d-flex justify-content-center align-items-center'>
                                    <Button style={{background: '#fff', border: 0, outline: 'none', boxShadow: 'none'}}
                                            onClick={() => alertDialogStore.open(
                                                'Gitlab 을 통한 소스코드관리',
                                                '',
                                                '깃랩은 github 와 비슷한 깃의 프론트엔드 지만 무제한 무료이면서, 설치형 버전을 제공한다는 장점이 있습니다.' +
                                                '만약 고객사의 요청이 있으면 설치형 깃랩을 써서 소스코드 저장소를 사내에 위치시키는것이 가능합니다.' +
                                                '깃랩의 이슈트래커와 칸반보드 등의 기능은 상당히 간소하면서 훌륭합니다.'
                                            )}>
                                        <img className='w-100'
                                             src="/asset/img/gitlab.png" alt=""/>
                                    </Button>
                                </Col>
                                <Col md={2} xs={4} className='p-0 d-flex justify-content-center align-items-center'>
                                    <Button style={{background: '#fff', border: 0, outline: 'none', boxShadow: 'none'}}
                                            onClick={() => alertDialogStore.open(
                                                'Slack 을 통한 커뮤니케이션',
                                                '',
                                                '슬랙은 다양한 서비스와의 연동기능을 지원하는 업무용 팀 메신저입니다.' +
                                                '슬랙을 통한 깃랩 연동으로 각 이슈와 진행상태를 쉽게 알 수 있으며, 각종 어플리케이션의 상태나 서버의 모니터링, 또 주기적으로 실행되어야 하는 업무의 실행및 진행과정을 알 수 있게 해줍니다.'
                                            )}>
                                        <img className='w-100'
                                             src="/asset/img/slack.png" alt=""/>
                                    </Button>
                                </Col>
                                <Col md={2} xs={4} className='p-0 d-flex justify-content-center align-items-center'>
                                    <Button style={{background: '#fff', border: 0, outline: 'none', boxShadow: 'none'}}
                                            onClick={() => alertDialogStore.open(
                                                'Spring 기반의 MVC 패턴',
                                                '',
                                                '스프링 기반의 DI 와 프록시를 통한 AOP 를 적용합니다.' +
                                                '스프링 WebMVC 를 통해 이를 구현한 웹 프레임워크에 기반하여 개발합니다.' +
                                                '혹은 스프링 기반의 소켓서버를 만들거나 jax/rs 표준을 준수하는 api 서버를 작성하기도 합니다.'
                                            )}>
                                        <img className='w-100'
                                             src="/asset/img/spring.png" alt=""/>
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </Section>
                    <Section>
                        <Container className='d-flex flex-column justify-content-center align-items-center h-100'>
                            <div className={Styles.title}>MEMBER <div className={Styles.redPoint}/></div>
                            <CardGroup className='d-flex flex-row'>
                                <Card>
                                    <Card.Img variant="top" src="/asset/img/euhyen.jpg"/>
                                    <Card.Body>
                                        <Card.Title>장유현</Card.Title>
                                        <Card.Text>
                                            developer
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Img variant="top" src="/asset/img/jungeun.jpg"/>
                                    <Card.Body>
                                        <Card.Title>유정은</Card.Title>
                                        <Card.Text>
                                            designer
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Img variant="top" src="/asset/img/simin.jpg"/>
                                    <Card.Body>
                                        <Card.Title>신승민</Card.Title>
                                        <Card.Text>
                                            developer
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </CardGroup>
                        </Container>
                    </Section>
                    <Section>
                        <Container className='w-100 h-100 d-flex flex-column justify-content-center'>
                            <div className={Styles.title}>CONTACT US <div className={Styles.redPoint}/></div>
                            <Row>
                                <Col md={6}>
                                    <Form>
                                        <Form.Group className='d-flex flex-column w-100 mb-1'>
                                            <Form.Control store={applyFormStore} name={"email"}
                                                          validations={[new Validation(/^.+/, '신청자 이메일을 입력하세요.')]}
                                                          validOnBlur
                                                          placeholder={'email'}
                                                          onChange={e=>
                                                              applyFormStore.setValue('email',e.target.value)
                                                          }
                                            />
                                        </Form.Group>
                                        <Form.Group className='d-flex flex-column w-100 mb-1'>
                                            <Form.Control store={applyFormStore} name={"phone"}
                                                          validations={[new Validation(/^.+/, '연락처를 입력하세요.')]}
                                                          validOnBlur
                                                          placeholder={'phone'}
                                                          onChange={e=>
                                                              applyFormStore.setValue('phone',e.target.value)
                                                          }
                                            />
                                        </Form.Group>
                                        <Form.Group className='d-flex flex-column w-100 mb-1'>
                                            <Form.Control as="textarea" aria-label="With textarea"
                                                          rows={6}
                                                          store={applyFormStore} name={"content"}
                                                          validations={[new Validation(/^.+/, '문의내용을 입력하세요.')]}
                                                          validOnBlur
                                                          placeholder={'text'}
                                                          onChange={e=>
                                                              applyFormStore.setValue('content',e.target.value)
                                                          }
                                            />
                                        </Form.Group>
                                        <Form.Group className='d-flex flex-column w-100 mb-1'>
                                            <Button onClick={() => this.send()} variant=""
                                                    style={{backgroundColor: '#f32938', border: 0}}>
                                                문의하기
                                            </Button>
                                        </Form.Group>
                                    </Form>
                                </Col>
                                <Col md={6}>
                                    <div className={Styles.googleMap}>
                                        <GoogleMapReact
                                            bootstrapURLKeys={{key: 'AIzaSyDxx0f_CMubae1vqSdKiiAso0-EsMVKBB4'}}
                                            defaultCenter={escKorea}
                                            defaultZoom={16}
                                            onGoogleApiLoaded={({map, maps}) => {
                                                let marker = new maps.Marker({
                                                    position: escKorea,
                                                    map,

                                                });
                                            }}
                                        >
                                        </GoogleMapReact>
                                    </div>
                                    <div>
                                        <span className='font-weight-bold'>ESC-KOREA</span>
                                        <p style={{fontSize: '0.875em', lineHeight: '1.5em'}}>인천광역시 서구 연희동 741-14 그린스마트빌
                                            302호<br/>
                                            전화번호 010-2856-4218<br/>
                                            이메일 euhyen@gmail.com
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Section>

                    {/*<footer>*/}
                    {/*    <Container className={`${Styles.Footer}`}>*/}
                    {/*        <Row>*/}
                    {/*            <Col md={12}>*/}
                    {/*                <h4 style={{fontWeight: 'bold'}}>이에스씨코리아</h4>*/}
                    {/*                <h5>ESC-KOREA</h5>*/}
                    {/*                <p>*/}
                    {/*                    사업자등록번호:573-06-00908 | 대표자:장유현<br/>*/}
                    {/*                    인천광역시 서구 연희동 741-14 그린스마트빌 302호*/}
                    {/*                </p>*/}
                    {/*                <br/><br/><br/>*/}
                    {/*                <h5>고객센터</h5>*/}
                    {/*                <p>*/}
                    {/*                    운영시간 평일 오전 10:30 ~ 오후 17:00까지<br/>*/}
                    {/*                    T 010-2856-4218<br/>*/}
                    {/*                    E hyen@esc-korea.com*/}
                    {/*                </p>*/}
                    {/*                <br/><br/><br/>*/}
                    {/*                <div className={Styles.footerLink}>*/}
                    {/*                    <div style={{marginRight: '15px'}}>*/}
                    {/*                        <a href="https://app.picknpick.world/api/views/terms/MEMBER">서비스 이용약관</a>*/}
                    {/*                    </div>*/}
                    {/*                    <div>*/}
                    {/*                        <a href="https://app.picknpick.world/api/views/terms/PRIVACY">개인정보처리방침</a>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            </Col>*/}
                    {/*        </Row>*/}
                    {/*    </Container>*/}
                    {/*</footer>*/}
                </SectionsContainer>
            </div>
        );
    }
}

IntroView.propTypes = {};

export {IntroView, introStore};
