import React from 'react';
import NotificationSystem from 'react-notification-system';

let notificationRef;

const Notification = () =>
    <NotificationSystem ref={ref => notificationRef = ref}/>;


export {Notification, notificationRef};



