import React from 'react';
import {observer} from 'mobx-react';
import Styles from './SideBar.module.scss';
import appStore from '../../store/AppStore';
import {MdClear, MdRemove, MdAdd} from 'react-icons/md';
import {Button, Collapse} from '@material-ui/core';
import {observable} from 'mobx';
import {navigate, navigateReplace} from '../../helper/HistoryHelper';
import {loginDialogStore} from "../dialog/LoginDialog";

class SideBarStore {
    @observable
    openedMenu = undefined;
}

const sideBarStore = new SideBarStore();

const SideBarSubMenu = ({title, to}) =>
    <div className={Styles.SideBarSubMenu}>
        <Button onClick={() => {
            navigate(to);
            appStore.sideBarOpen = false;
        }}>
            {title}
        </Button>
    </div>;

const SideBarMenu = observer( ({id, title, to, children}) => {

    return (
        <div className={Styles.SideBarMenu} onClick={() => {
            if(children) {
                if(sideBarStore.openedMenu === id) {
                    sideBarStore.openedMenu = undefined;
                } else {
                    sideBarStore.openedMenu = id;
                }
            } else {
                navigate(to);
                appStore.sideBarOpen = false;
            }
        }}>
            <Button>
                {title}
                {
                    children ? (sideBarStore.openedMenu === id ? <span><MdRemove/></span> : <span><MdAdd/></span>) : null
                }
            </Button>
            {
                children ?
                    <Collapse in={sideBarStore.openedMenu === id}>
                        {
                            children
                        }
                    </Collapse>
                    : null
            }
        </div>
    );
});



@observer
class SideBar extends React.Component {


    render() {
        return (
            <div className={`${Styles.SideBar} ${appStore.sideBarOpen ? Styles.active : ''}`}>
                <div className={Styles.SideBarHeader}>
                    <Button onClick={() => appStore.sideBarOpen = false}>
                        <MdClear style={{width: 30, height: 30}}/>
                    </Button>
                </div>
                <div className={Styles.SideBarBody}>
                    <SideBarMenu title="HOME" id={1} to="/intro/home" />
                    <SideBarMenu title="PORTFOLIO" id={2} to="/intro/portfolio" />
                    <SideBarMenu title="TECH STACK" id={3} to="/intro/tech" />
                    <SideBarMenu title="MEMBER" id={4} to="/intro/member" />
                    <SideBarMenu title="CONTACT US" id={5} to="/intro/contact" />
                </div>
            </div>
        );
    }
}

export default SideBar